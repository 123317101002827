import {Component, OnInit} from '@angular/core';
import {PortfolioService} from '../../../../core/services/portfolio/portfolio.service';
import {PortfolioListItem} from '../../../../shared/models/portfolio-list-item/portfolio-list-item';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  portfolioItems: Array<PortfolioListItem>;
  public page: number;
  public maxPage: number;
  loading: boolean;

  constructor(private portfolioService: PortfolioService) {
  }

  ngOnInit(): void {
    this.page = 0;
    this.getPortfolioCount();
    this.getPortfolioItems();
    this.loading = true;
  }

  getPortfolioItems() {
    this.portfolioService.getItemsPerPage(this.page).then(data => {
      this.portfolioItems = data;
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    });
  }

  stopLoading() {
    this.loading = false;
  }

  getPortfolioCount() {
    this.portfolioService.getItemsCount().then(data => {
      this.maxPage = data;
    });
  }

}
