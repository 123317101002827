import { Component, OnInit } from '@angular/core';
import {ReCaptchaV3Service} from 'ngx-captcha';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactService} from '../../../../core/services/contact/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  submitted: number;
  name = new FormControl('', [Validators.required, Validators.minLength(1)]);
  mail = new FormControl('', [Validators.required, Validators.minLength(1)]);
  phone = new FormControl('', [Validators.required, Validators.minLength(1)]);
  message = new FormControl();
  robots: boolean;
  recaptcha: any;

  constructor( private contactService: ContactService) { }

  ngOnInit() {
    this.submitted = 0; // set submitted to false
    this.robots = false; // robot is default
  }

  submit() {
    this.submitted = 1; // loading
    this.contactService.sendMessage(this.message.value, this.mail.value, this.name.value, this.phone.value).then(data => {
      this.submitted = 2;
    }, ( error => {
      this.submitted = 0;
      alert('Er is iets mis gegaan, probeer later opnieuw');
    }));
  }


}
