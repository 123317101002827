import {NgModule} from '@angular/core';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule, MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule, MatListModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatSnackBarModule,
  MatStepperModule, MatTabsModule,
  MatTooltipModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import {TokenInterceptor} from '../core/interceptors/token/token.interceptor';
import {ImageService} from '../core/services/image/image.service';
import {PortfolioService} from '../core/services/portfolio/portfolio.service';
import { SafePipe } from './pipes/safe/safe.pipe';
import {RecaptchaModule} from 'angular-google-recaptcha';
import {RECAPTCHA_SETTINGS, RecaptchaSettings} from 'ng-recaptcha';
import {NgxCaptchaModule} from 'ngx-captcha';





@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatExpansionModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatTabsModule,
    FormsModule,
    MatChipsModule,
    RouterModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    MatListModule,

  ],
  declarations: [TruncatePipe, SafePipe],
  exports: [
    BrowserModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatExpansionModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatTabsModule,
    FormsModule,
    MatChipsModule,
    RouterModule,
    BrowserAnimationsModule,
    TruncatePipe,
    SafePipe,
    NgxCaptchaModule,
    MatListModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    ImageService,
    PortfolioService,
  ],
})


export class SharedModule {
}
