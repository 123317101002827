import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @ViewChild('collapseNavBar') collapseNavBar: ElementRef;

  constructor(private router: Router) {
    router.events.subscribe(() => {
      if (window.innerWidth < 768 && !this.collapseNavBar.nativeElement.classList.contains('collapsed')) {
        this.collapseNavBar.nativeElement.click();
      }
    });

  }

  ngOnInit() {

  }


}
