export class PortfolioItem {
  title: any;
  description: any;
  header: any;

  constructor(title: any, description: any, header: any) {
    this.title = title;
    this.description = description;
    this.header = header;
  }
}
