export class PortfolioListItem {
  isPublished: boolean;
  featuredImage: string;
  title: string;
  description: string;
  color: string;
  portfolioItemLink: string;

  constructor(isPublished: boolean, featuredImage: string, title: string, description: string, color: string, portfolioItemLink: string) {
    this.isPublished = isPublished;
    this.featuredImage = featuredImage;
    this.title = title;
    this.description = description;
    this.color = color;
    this.portfolioItemLink = portfolioItemLink;
  }

}
