/* tslint:disable:no-string-literal */
import {Component, OnInit} from '@angular/core';
import {fadeAnimation} from '../website.animation';

@Component({
  selector: 'app-shell-website',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css'],
  animations: [fadeAnimation],
})
export class ShellComponent implements OnInit {
  ngOnInit(): void {}



}
