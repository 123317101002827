import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ShellComponent} from './shell/shell.component';
import {HomeComponent} from './pages/home/home.component';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';
import {PortfolioItemComponent} from './pages/portfolio-item/portfolio-item.component';
import {LegalComponent} from './pages/legal/legal.component';
import {ContactComponent} from './pages/contact/contact.component';
import {ServicesComponent} from './pages/services/services.component';


// Routes
const routes: Routes = [
  {
    path: '', component: ShellComponent, children: [
      {
        path: '', pathMatch: 'full', component: HomeComponent,
        data: {
          title: 'Freelancer - Developer, Design & Marketing',
          description: 'Klanten staan centraal bij het ontwikkelen, designen en of campagnes.' +
            ' Samen komen we zo tot het beste resultaat voor jou en je klanten!',
          keywords: ['Design', 'Freelancer', 'Development', 'Angular', +
            'Developer in Enschede', 'Designer in Enschede', +
            'App bouwen enschede' + 'Website bouwen enschede', +
            'goedkoop website bouwen enschede', +
            'Flyers ontwikkelen enschede', 'flyers ontwikkelen']
        }
      },
      {
        path: 'portfolio', component: PortfolioComponent,
        data: {
          title: 'Portfolio - Developer, Design & Marketing',
          description: 'Bekijk welke opdrachten ik heb gedaan voor verschillende klanten over ' +
            'de hele wereld. Van development tot design. Bekijk nu mijn portfolio',
          keywords: ['Design', 'Freelancer', 'Development', 'Angular', +
            'Developer in Enschede', 'Designer in Enschede', +
            'App bouwen enschede' + 'Website bouwen enschede', +
            'goedkoop website bouwen enschede', +
            'Flyers ontwikkelen enschede', 'flyers ontwikkelen']
        }
      },
      {
        path: 'portfolio/:id', component: PortfolioItemComponent,
        data: {
          title: 'Artikel - Developer, Design & Marketing',
          description: 'Bekijk welke opdrachten ik heb gedaan voor verschillende klanten over ' +
            'de hele wereld. Van development tot design. Bekijk nu mijn portfolio',
          keywords: ['Design', 'Freelancer', 'Development', 'Angular', +
            'Developer in Enschede', 'Designer in Enschede', +
            'App bouwen enschede' + 'Website bouwen enschede', +
            'goedkoop website bouwen enschede', +
            'Flyers ontwikkelen enschede', 'flyers ontwikkelen']
        }
      },
      {
        path: 'legal', component: LegalComponent,
        data: {
          title: 'Legal - Developer, Design & Marketing',
          description: 'Lees o.a. de algemene voorwaarden die gelden op de opdrachten verstrekt aan Joey Jreij en andere legal documenten.',
          keywords: ['Design', 'Freelancer', 'Development', 'Angular', +
            'Developer in Enschede', 'Designer in Enschede', +
            'App bouwen enschede' + 'Website bouwen enschede', +
            'goedkoop website bouwen enschede', +
            'Flyers ontwikkelen enschede', 'flyers ontwikkelen']
        }
      },
      {
        path: 'services', component: ServicesComponent,
        data: {
          title: 'Diensten - Developer, Design & Marketing',
          description: 'Benieuwd wie ik ben? En welke ervaringen  en expertises ik heb? Bekijk het hier!',
          keywords: ['Design', 'Freelancer', 'Development', 'Angular', +
            'Developer in Enschede', 'Designer in Enschede', +
            'App bouwen enschede' + 'Website bouwen enschede', +
            'goedkoop website bouwen enschede', +
            'Flyers ontwikkelen enschede', 'flyers ontwikkelen']
        }
      },
      {
        path: 'contact', component: ContactComponent,
        data: {
          title: 'Contact - Developer, Design & Marketing',
          description: 'Op zoek naar een Freelancer developer, designer en/of marketeer, neem dan contact met me op zodat we kunnen bekijken wat we voor elkaar kunnen betekenen.',
          keywords: ['Design', 'Freelancer', 'Development', 'Angular', +
            'Developer in Enschede', 'Designer in Enschede', +
            'App bouwen enschede' + 'Website bouwen enschede', +
            'goedkoop website bouwen enschede', +
            'Flyers ontwikkelen enschede', 'flyers ontwikkelen']
        }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
  providers: []
})
export class WebsiteRoutingModule {
}
