/* tslint:disable:no-string-literal */
import {Component, OnInit} from '@angular/core';
import {PortfolioService} from '../../../../core/services/portfolio/portfolio.service';
import {PortfolioListItem} from '../../../../shared/models/portfolio-list-item/portfolio-list-item';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  portfolioItems: Array<PortfolioListItem>;

  constructor(private portfolioService: PortfolioService) {
  }

  ngOnInit(): void {
    this.getPortfolioItems();
  }

  getPortfolioItems() {
    this.portfolioService.getLatestItems().then(data => {
      this.portfolioItems = data;
    });
  }


}
