import {NgModule} from '@angular/core';
import {WebsiteRoutingModule} from './website-routing.module';
import {ShellComponent} from './shell/shell.component';
import {SharedModule} from '../../shared/shared.module';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { PortfolioListItemComponent} from './components/portfolio-list-item/portfolio-list-item.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import {PortfolioItemComponent} from './pages/portfolio-item/portfolio-item.component';
import { HeaderComponent } from './components/header/header.component';
import { LegalComponent } from './pages/legal/legal.component';
import {ContactComponent} from './pages/contact/contact.component';
import { ServicesComponent } from './pages/services/services.component';






@NgModule({
  imports: [
    SharedModule,
    WebsiteRoutingModule,
  ],
  declarations: [ShellComponent, HomeComponent, NavbarComponent, FooterComponent, PortfolioItemComponent, LoadingComponent, PortfolioComponent, PortfolioListItemComponent, HeaderComponent, LegalComponent, ContactComponent, ServicesComponent],
})


export class WebsiteModule {
}
