import {environment} from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  const; base: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  public getImage(path: string, width: number, height: number) {

    return fetch(this.base + 'cockpit/image?token=' + environment.token, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        src: path,
        w: width,
        h: height
      })
    })
      .then(url => {
        return url.text();
      });
  }

}
