import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ShellComponent } from './shell/shell.component';
import {SharedModule} from './shared/shared.module';
import {WebsiteModule} from './modules/website/website.module';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [
    ShellComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    WebsiteModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [ShellComponent]
})
export class AppModule { }
