/* tslint:disable:no-string-literal */
import {environment} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PortfolioListItem} from '../../../shared/models/portfolio-list-item/portfolio-list-item';
import {PortfolioItem} from '../../../shared/models/portfolio-item/portfolio-item';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  const; base: string = environment.apiUrl;

constructor(private httpClient: HttpClient) {}

  public getLatestItems(): Promise<Array<PortfolioListItem>> {

  return this.httpClient.post(this.base + 'collections/get/portfolioListItem', {
      filter: {is_published: true},
      limit: 3,
      sort: {_created: -1}
    }).toPromise().then(data => {

    const portfolioListItems: Array<PortfolioListItem> = [];

    for (const item of data['entries']) {
        const portfolioListItem = new PortfolioListItem(item['is_published'], item['featured_image']['path'], item['title'], item['description'], item['color'], item['portfolio_item_link']['_id']);
        portfolioListItems.push(portfolioListItem);
      }
    return portfolioListItems;
    });
  }
  public getItemsPerPage(page: number): Promise<Array<PortfolioListItem>> {

    return this.httpClient.post(this.base + 'collections/get/portfolioListItem', {
      filter: {is_published: true},
      limit: 9,
      skip: page * 9
    }).toPromise().then(data => {

      const portfolioListItems: Array<PortfolioListItem> = [];

      for (const item of data['entries']) {
        const portfolioListItem = new PortfolioListItem(item['is_published'], item['featured_image']['path'], item['title'], item['description'], item['color'], item['portfolio_item_link']['_id']);
        portfolioListItems.push(portfolioListItem);
      }
      return portfolioListItems;
    });
  }
  public getItemsCount(): Promise<number> {
    return this.httpClient.post(this.base + 'collections/get/portfolioListItem', {
      filter: {is_published: true},
    }).toPromise().then(data => {
      return (Math.ceil(data['entries'].length / 9));
    });
  }

  public getItem(id: string): Promise<PortfolioItem> {

    return this.httpClient.post(this.base + 'collections/get/portfolioItem', {
      filter: {_id: id},
    }).toPromise().then(data => {
      return (new PortfolioItem(data['entries'][0]['title'], data['entries'][0]['description'], data['entries'][0]['header']['path']));
    });
  }


}
