import {Component, OnInit} from '@angular/core';
import {PortfolioService} from '../../../../core/services/portfolio/portfolio.service';
import {ActivatedRoute} from '@angular/router';
import {PortfolioItem} from '../../../../shared/models/portfolio-item/portfolio-item';
import {ImageService} from '../../../../core/services/image/image.service';
import {DomSanitizer} from '@angular/platform-browser';
import {element} from 'protractor';

@Component({
  templateUrl: './portfolio-item.component.html',
  styleUrls: ['./portfolio-item.component.css']
})
export class PortfolioItemComponent implements OnInit {

  id: string;
  portfolioItem: PortfolioItem;
  image: any;
  loading: boolean;

  constructor(private portfolioService: PortfolioService, private route: ActivatedRoute, private imageService: ImageService, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.loading = true;
    this.getPortfolioItem();
  }

  getPortfolioItem() {
    this.portfolioService.getItem(this.route.snapshot.params.id).then(data => {
      this.portfolioItem = data;
      this.getImage();
    }).catch(error => {
      console.log(error);
    });
  }

  public getImage() {
    return this.imageService.getImage(this.portfolioItem.header, 2000, 1000).then(url => {
      this.image = url;
      this.getImageSafe();
      setTimeout(() => {
        this.loading = false;
      }, 500);
    });
  }


  public getImageSafe() {
    this.image = this.sanitizer.bypassSecurityTrustStyle(`url(${this.image})`);
  }
}
