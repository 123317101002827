import {Injectable} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  updateMetaInfo(description, author, category) {
    this.meta.updateTag({name: 'description', content: description});
    this.meta.updateTag({name: 'author', content: author});
    this.meta.updateTag({name: 'keywords', content: category});
  }

  updateTitle(title?: string) {
    if (!title) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)).subscribe((event) => {
        this.titleService.setTitle(event.title + ' | Joey Jreij');
      });
    } else {
      this.titleService.setTitle(title + ' | Joey Jreij');
    }
  }
}
