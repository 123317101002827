import {Component} from '@angular/core';
import {SeoService} from '../core/services/seo/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css']
})
export class ShellComponent {

  constructor(private seoService: SeoService) {
    // call this method to enable activated routes automatic change meta and title
    this.seoService.updateTitle();
  }
}
