import { Component, OnInit } from '@angular/core';
import {Question} from '../../../../shared/models/question/question';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  questions: Array<Question> = [];

  constructor() { }

  ngOnInit() {
    this.setQuestions();
  }

  /**
   * set questions FAQ
   */
  private setQuestions(): void {
    const q1 = new Question('Doe je ook aan hosting?', 'Ja, voor hosting doet Joey Jreij zaken met TransIP. Dit is één van de beste hosting providers in Nederland, mede doordat ze voldoen aan alle belangrijke ISO certificaten. Er zijn verschillende paketten verkrijgbaar. Van Hosting, Wordpress hosting tot een eigen virtuele server. Voordelen van het hosten bij Joey Jreij en niet direct bij een partij, is dat je een snelle persoonlijke ondersteuning hebt tegen een relatief lage meerprijs. ');
    const q2 = new Question('Doe je ook aan email hosting?', 'Ja, bij normale hosting zit al standaard email hosting bij in. Ook is het mogelijk om alleen email te hosten zonder dat je een website host.');
    const q3 = new Question('Zijn mijn gegevens beveiligd?', 'Joey Jreij doet zaken met TransIP als het gaat om hosting. TransIP beschikt over alle belangrijke ISO certificaten. Tevens kun je voor de domein een SSL activeren, die ervoor zorgt dat al je data beveiligd over het internet wordt verzonden.');
    const q4 = new Question('Hoeveel opslag krijg ik bij mijn hosting?', 'Dit is erg afhankelijk van welk pakket je kiest. Zo begint normale webhosting met 10gb en is uit te breiden tot 100gb. Indien je meer opslag en werkgeheugen wilt, kunnen we overstappen op een Virtuele Server. Virtuele servers wordt meestal gebruikt voor grotere websites / webapplicaties.');
    const q5 = new Question('Kan ik je ook inhuren voor een langere periode?', 'Vanuit meerdere klanten wordt de vraag gesteld of ze de diensten van Joey Jreij maandelijks kunnen inzetten. Dit kan! Er kan een SLA overeenkomst worden aangegaan met Joey Jreij, waarin wordt vastgesteld welke diensten er per wederkerende periode gedaan moeten worden.');
    const q6 = new Question('Wat zijn de voordelen van het inhuren?', 'Inhuren van de diensten van Joey Jreij brengt het grote voordeel mee dat je elke keer met dezelfde persoon te maken krijgt. Dit betekend dus dat je niet elke keer op zoek moet naar iemand die soortgelijke diensten levert, zich eerst moet inlezen en inwerken en kom je niet voor onvoorziene kosten te staan. Alles wordt van te voren vastgesteld in het SLA.');
    // const q7 = new Question('', 'a');
    // const q8 = new Question('aa', 'a');
    // const q9 = new Question('aa', 'a');
    // const q10 = new Question('aa', 'a');
    // const q11 = new Question('aa', 'a');
    // const q12 = new Question('aa', 'a');

    // push the questions
    this.questions.push(q1, q2, q3, q4, q5, q6); // , q8, q9, q10, q11, q12 ,q7
  }
}
