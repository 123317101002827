/* tslint:disable:no-string-literal object-literal-shorthand */
import {environment} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  const;
  base: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) {
  }

  public sendMessage(message: string, mail: string, name: string, phone: string): Promise<any> {
    return this.httpClient.post(this.base + 'forms/submit/contact', {
      form: {
        name,
        message,
        mail,
        phone,
      },
    }).toPromise().then(data => {
      return data;
    });
  }
}
