import {Component, Input, OnInit} from '@angular/core';
import {ImageService} from '../../../../core/services/image/image.service';
import {PortfolioListItem} from '../../../../shared/models/portfolio-list-item/portfolio-list-item';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-portfolio-item',
  templateUrl: './portfolio-list-item.component.html',
  styleUrls: ['./portfolio-list-item.component.css']
})
export class PortfolioListItemComponent implements OnInit {
  /**
   * Inputs for the component
   */
  @Input() item: PortfolioListItem;
  imagePath: string;

  constructor(private imageService: ImageService, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.getImage();
  }

  public getImage() {
    if (this.item.featuredImage) {
      return this.imageService.getImage(this.item.featuredImage, 250, 250).then(url => {
        this.imagePath = url;
      });
    }
  }


}
